import React, { useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { CHARTS } from "../../constants/DashboardTranslation";

import { Link } from "react-router-dom";

import { Row, Col, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";
import LineChart from "./LineChart";
import SdohChart from "./SDOHChart";
import SubmitButton from "../StyledComponents/SubmitButton";

import lineChartIcon from "../../images/show_chart.svg";
import barChartIcon from "../../images/bar_chart.svg";
import LineIcon from "../../images/line.svg";
import PieIcon from "../../images/pie.svg";
import FeelingsIcon from "../../images/overall-feelings.svg";
import BarIcon from "../../images/bar.svg";
import TriggerDataIcon from "../../images/trigger-data.svg";

const Charts = ({ topSymptoms, data, error, retry, additionalNotes, user }) => {
  // console.log("11111111111", data) Why data in Charts.js render twice and the data is null for the second time.
  // console.log("data1", data)

  const { language } = useContext(PreferredLanguageContext);

  const LinkToReport = (title, icon) => (
    <Link to="/charts" className="report-link">
      <img src={icon} alt="icon" className="report-icon" />
      <span style={{ paddingLeft: "0.5rem" }}>{title}</span>
    </Link>
  );

  const lineChartTooltip = (
    <Tooltip id="line-chart-tooltip">{CHARTS[language].trend_charts}</Tooltip>
  );

  const barChartTooltip = (
    <Tooltip id="line-chart-tooltip">{CHARTS[language].trigger_data}</Tooltip>
  );

  return (
    <ComponentWrapper>
      <Tabs id="chartTab">
        {error && (
          <div>
            {CHARTS[language].error[0]}
            <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
          </div>
        )}

        <Tab
          eventKey="line"
          title={
            <OverlayTrigger placement="top" overlay={lineChartTooltip}>
              <img src={lineChartIcon} alt="line chart icon" />
            </OverlayTrigger>
          }
        >
          <LineChart
            topSymptoms={topSymptoms}
            data={data}
            additionalNotes={additionalNotes}
          />
        </Tab>
        <Tab
          eventKey="bar"
          title={
            <OverlayTrigger placement="top" overlay={barChartTooltip}>
              <img src={barChartIcon} alt="bar chart icon" />
            </OverlayTrigger>
          }
        >
          <SdohChart data={data} />
        </Tab>
        <Tab eventKey="Reports" title={CHARTS[language].detailed_reports.label}>
          <Row>
            <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.overall_feelings, FeelingsIcon)}</Col>
            <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.top_symptoms, BarIcon)}</Col>
          </Row>
          <Row>
            <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.medical_score, PieIcon)}</Col>
            <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.top_triggers, BarIcon)}</Col>
          </Row>
          <Row>
            <Col md={6}>{LinkToReport(CHARTS[language].detailed_reports.trigger_data, TriggerDataIcon)}</Col>
            <Col md={6}>
              {LinkToReport(CHARTS[language].detailed_reports.days_in_review, LineIcon)}
            </Col>
          </Row>
        </Tab>
        {
          (user.userType === "therapist" || user.userType === "provider") && (
            <Tab eventKey="CaseReport" title={CHARTS[language].case_report}>
              <Link to="/caseReport">{CHARTS[language].case_report}</Link>
            </Tab>
          )
        }
      </Tabs>
    </ComponentWrapper>
  );
};

export default Charts;
