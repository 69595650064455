import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext, PreferredLanguageContext } from "../lib/main-context";
import { CHARTS, EXPANDED_CHART, EXPORT_PDF, GO_BACK, INTENSITY_LEVEL, OFF_DARK_MODE_EXPORT } from "../constants/DashboardTranslation";

import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { parse } from "date-fns";
import Form from "react-bootstrap/Form";

import theme from "../index.scss";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import LineChartOptions from "./DashboardComponents/LineChartOptions";
import SubmitButton from "./StyledComponents/SubmitButton";
import ModalPdf from "./DashboardComponents/ModalPdf";
import DoughnutSdoh from "./DoughnutSdoh";
import DoughnutMedical from "./DoughnutMedical";
import SdohBarChart from "./SdohBarChart";
import MedicalBarChart from "./MedicalBarChart";
import FeelingBar from "./FeelingBar";
import PillButton from "./StyledComponents/PillButton";

import fillInDays from "../lib/fillInDates";
import getDaysAgo from "../lib/getDaysAgo";
import sdohWithSetVal from "../lib/sdohWithSetVal";

import { getAdditionalNote, getAnotherTPOrPatientTrackedData, getConnectedPatientOfTPData } from "../api/TBIRequests";

const ExpandedChart = ({
  user,
  userIdForTrackedData,
  error,
  topSymptoms,
  data,
  retry,
}) => {
  const location = useLocation();
  const history = useHistory();
  const days = location.state?.days || 30;

  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [assignedDays, setAssignedDays] = useState(days);
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [newData2, setNewData2] = useState({});
  const [anotherNewData2, setAnotherNewData2] = useState({});
  const [sdohByDays, setSdohByDays] = useState([]);
  const [anotherSdohByDays, setAnotherSdohByDays] = useState([]);
  const [showLineModal, setShowLineModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [splitView, setSplitView] = useState(false);
  const [TPOrPatientTrackedData, setTPOrPatientTrackedData] = useState([]);
  const [otherUserInfo, setOtherUserInfo] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [TPList, setTPList] = useState([]);

  const handleSubmit = async (selectedId) => {
    try {
      let params = {
        patientId: patient.patientId,
        curProviderId: user.userId,
        selectedId: selectedId,
      };
      if (user.userType === "provider") {
        if (userIdForTrackedData) {
          params.userId = userIdForTrackedData;
        }
      }

      const { data } = await getAnotherTPOrPatientTrackedData(params); // If no userIdForTrackedData, it is a provider and it's a patient user being clicked on the patient control page

      setTPOrPatientTrackedData(data.result);
      setOtherUserInfo(data.otherUserInfo[0]);
      setSplitView(true);
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      setErrorMessage(EXPANDED_CHART[language].error);
    }
  };

  const getData = async () => {
    try {
      let params = { patientId: patient.patientId };
      let data;
      if (user.userType === "provider") {
        params.curProviderId = user.userId;
        if (userIdForTrackedData) {
          params.userId = userIdForTrackedData;
        }
        ({ data } = await getAnotherTPOrPatientTrackedData(params)); // If no userIdForTrackedData, it is a provider and it's a patient user being clicked on the patient control page
      } else if (user.userType === 'Therapist') {
        ({ data } = await getConnectedPatientOfTPData(params));
        data.otherUserInfo = [{ tp_first_name: patient.firstName, tp_last_name: patient.lastName }];
      }

      // If no comparison then message is shown; else comparison is shown
      if (data.message) {
        // Handle error response
        if (data.TPList) {
          setTPList(data.TPList);
        }
        setErrorMessage(data.message);
      } else {
        setTPOrPatientTrackedData(data.result);
        setOtherUserInfo(data.otherUserInfo[0]);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(EXPANDED_CHART[language].error);
    }
  };

  const handleSplitViewShow = async () => {
    if (!splitView) {
      try {
        await getData();
      } catch (error) {
        console.error(error);
      }
    }
    setSplitView((prev) => !prev);
  };

  const anotherMedicalData = TPOrPatientTrackedData?.filter(
    (obj) => obj.category !== "SDOH"
  ).filter(
    (obj) =>
      parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
      getDaysAgo(assignedDays)
  );

  const anotherSdohData = TPOrPatientTrackedData?.filter(
    (obj) => obj.category == "SDOH"
  ).filter(
    (obj) =>
      parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
      getDaysAgo(assignedDays)
  );

  const medicalData = data
    ?.filter((obj) => obj.category !== "SDOH")
    .filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );

  const sdohData = data
    ?.filter((obj) => obj.category == "SDOH")
    .filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );

  const colors = [
    theme.chart1,
    theme.chart2,
    theme.chart3,
    theme.chart4,
    theme.chart5,
    theme.chart6,
    theme.chart7,
    theme.chart8,
    theme.chart9,
    theme.chart10,
  ];
  const handleLineModalClose = () => setShowLineModal(false);
  const handleLineModalShow = () => setShowLineModal(true);

  useEffect(() => {
    getAdditionalNote(patient.patientId)
      .then(({ data }) => {
        setAdditionalNotes(data);
      })
      .catch((e) => console.log("getAdditionalNotesError", e));
  }, []);

  let filterDataByDays = (dataset) => {
    const datasetConvertedToChartData = dataset.map((item) => {
      return {
        // CURRENT BUG, Must remove time stamp or labels do not work correctly
        x: item.symptom_date.slice(0, 10),
        y: item.severity,
        desc: item.description,
      };
    });

    const filteredByDays = datasetConvertedToChartData.filter(
      (item) =>
        parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(assignedDays)
    );
    return filteredByDays;
  };

  let datasetArr = topSymptoms.map((symptom) => {
    return data.filter(({ factor }) => factor === symptom.factor);
  });

  datasetArr.push(sdohByDays);

  let newData = {
    datasets: [],
    notes: additionalNotes,
  };
  let { datasets } = newData;

  datasetArr.forEach((dataset, index) => {
    const isLastDataset = index === datasetArr.length - 1;
    const datasetData = filterDataByDays(dataset);

    datasets.push({
      label: isLastDataset ? "SDOH" : topSymptoms[index].factor,
      data: datasetData,
      pointStyle: isLastDataset ? "triangle" : undefined,
      type: isLastDataset ? "scatter" : undefined,
      backgroundColor: isLastDataset ? theme.primary : colors[index],
      borderColor: isLastDataset ? theme.primary : colors[index],
      borderWidth: 1,
      fill: isLastDataset ? false : undefined,
      hoverBackgroundColor: isLastDataset ? theme.primary : colors[index],
      hoverBorderColor: "white",
    });
  });

  let ChartDataset = fillInDays(datasets, assignedDays);
  newData.datasets = ChartDataset;

  //data for compare
  let datasetArrC = topSymptoms.map((symptom) => {
    return TPOrPatientTrackedData.filter(
      ({ factor }) => factor === symptom.factor
    );
  });

  datasetArrC.push(anotherSdohByDays);

  let newDataC = {
    datasets: [],
    notes: additionalNotes,
  };
  // let { datasets } = newDataC;

  datasetArrC.forEach((dataset, index) => {
    const isLastDataset = index === datasetArrC.length - 1;
    const datasetData = filterDataByDays(dataset);

    newDataC.datasets.push({
      label: isLastDataset ? "SDOH" : topSymptoms[index].factor,
      data: datasetData,
      pointStyle: isLastDataset ? "triangle" : undefined,
      type: isLastDataset ? "scatter" : undefined,
      backgroundColor: isLastDataset ? theme.primary : colors[index],
      borderColor: isLastDataset ? theme.primary : colors[index],
      borderWidth: 1,
      fill: isLastDataset ? false : undefined,
      hoverBackgroundColor: isLastDataset ? theme.primary : colors[index],
      hoverBorderColor: "white",
    });
  });

  let ChartDatasetC = fillInDays(newDataC.datasets, assignedDays);
  newDataC.datasets = ChartDatasetC;

  useEffect(() => {
    datasetArr = topSymptoms.map((symptom) => {
      return data.filter(({ factor }) => factor === symptom.factor);
    });

    const sdohList = sdohWithSetVal(data);
    const sdohByDays = sdohList.filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );
    setSdohByDays(sdohByDays);
    datasetArr.push(sdohList);

    datasets = [];
    datasetArr.forEach((dataset, index) => {
      const isLastDataset = index === datasetArr.length - 1;
      const datasetData = filterDataByDays(dataset);

      datasets.push({
        label: isLastDataset ? "SDOH" : topSymptoms[index].factor,
        data: datasetData,
        pointStyle: isLastDataset ? "triangle" : undefined,
        type: isLastDataset ? "scatter" : undefined,
        backgroundColor: isLastDataset ? theme.primary : colors[index],
        borderColor: isLastDataset ? theme.primary : colors[index],
        borderWidth: 1,
        fill: false,
        hoverBackgroundColor: isLastDataset ? theme.primary : colors[index],
        hoverBorderColor: "white",
      });
    });

    ChartDataset = fillInDays(datasets, assignedDays);
    const firstDay = getDaysAgo(assignedDays - 1);
    const formattedStartDate = firstDay?.toString().substring(0, 15);
    setStartDate(formattedStartDate);

    newData.datasets = ChartDataset;
    setNewData2(newData);

    //data for compare
    datasetArrC = topSymptoms.map((symptom) => {
      return TPOrPatientTrackedData.filter(
        ({ factor }) => factor === symptom.factor
      );
    });

    const sdohListC = sdohWithSetVal(TPOrPatientTrackedData);
    const anotherSdohByDays = sdohListC.filter(
      (obj) =>
        parse(obj.symptom_date.slice(0, 10), "yyyy-MM-dd", new Date()) >
        getDaysAgo(assignedDays)
    );
    setAnotherSdohByDays(anotherSdohByDays);
    datasetArrC.push(sdohListC);

    newDataC.datasets = [];
    datasetArrC.forEach((dataset, index) => {
      const isLastDataset = index === datasetArrC.length - 1;
      const datasetData = filterDataByDays(dataset);

      newDataC.datasets.push({
        label: isLastDataset ? "SDOH" : topSymptoms[index].factor,
        data: datasetData,
        pointStyle: isLastDataset ? "triangle" : undefined,
        type: isLastDataset ? "scatter" : undefined,
        backgroundColor: isLastDataset ? theme.primary : colors[index],
        borderColor: isLastDataset ? theme.primary : colors[index],
        borderWidth: 1,
        fill: false,
        hoverBackgroundColor: isLastDataset ? theme.primary : colors[index],
        hoverBorderColor: "white",
      });
    });

    ChartDatasetC = fillInDays(newDataC.datasets, assignedDays);

    newDataC.datasets = ChartDatasetC;
    setAnotherNewData2(newDataC);
  }, [assignedDays, TPOrPatientTrackedData]);

  const extendedChartOption1 = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: `${assignedDays} ${EXPANDED_CHART[language].days_in_review} ${splitView ? `(${patient.firstName})` : ""}`,
    },
    fontFamily: "quicksand",
    fontStyle: "bold",
    fontSize: "20",
    fontColor: dark ? theme.darkModeText : theme.black,
    legend: {
      labels: {
        fontColor: dark ? theme.darkModeText : theme.black,
        usePointStyle: true,
        boxWidth: 8,
      },
    },
  }

  const extendedChartOption2 = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: `${assignedDays} ${EXPANDED_CHART[language].days_in_review} 
      (${patient.caregiverName
          ? otherUserInfo.patient_first_name
          : otherUserInfo.tp_first_name
        })`,
      fontFamily: "quicksand",
      fontStyle: "bold",
      fontSize: "20",
      fontColor: dark ? theme.darkModeText : theme.black,
    },
    legend: {
      labels: {
        fontColor: dark ? theme.darkModeText : theme.black,
        usePointStyle: true,
        boxWidth: 8,
      },
    },
  };

  const colorOverRide = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "dddd",
          },
          ticks: {
            fontColor: dark ? theme.darkModeText : theme.black,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: dark ? theme.darkModeText : theme.black,
          },
          scaleLabel: {
            display: true,
            labelString: INTENSITY_LEVEL[language],
            fontColor: dark ? theme.darkModeText : theme.black,
          },
        },
      ],
    },
  };

  return (
    <>
      {error && (
        <ComponentWrapper>
          {CHARTS[language].error[0]}
          <SubmitButton onClick={retry}>{CHARTS[language].error[1]}</SubmitButton>
        </ComponentWrapper>
      )}
      <ComponentWrapper>
        <Row className="d-flex justify-content-around">
          <Form.Group controlId="formBasicSelect">
            <Form.Label>{EXPANDED_CHART[language].days_since} {startDate} </Form.Label>
            <Form.Control
              as="select"
              // value={date}
              defaultValue={assignedDays}
              onChange={(e) => {
                setAssignedDays(e.target.value);
              }}
            >
              <option value={5}>{`5 ${EXPANDED_CHART[language].days_in_review}`}</option>
              <option value={30}>{`30 ${EXPANDED_CHART[language].days_in_review}`}</option>
              {/* <option value={60}>{`60 ${EXPANDED_CHART[language].days_in_review}`}</option> */}
              <option value={90}>{`90 ${EXPANDED_CHART[language].days_in_review}`}</option>
              <option value={120}>{`120 ${EXPANDED_CHART[language].days_in_review}`}</option>
            </Form.Control>
          </Form.Group>

          <SubmitButton
            onClick={() => {
              if (dark) {
                alert(OFF_DARK_MODE_EXPORT[language]);
              } else {
                handleLineModalShow();
              }
            }}
          >
            {EXPORT_PDF[language]}
          </SubmitButton>
          {(user.userType == "provider" || user.userType == 'Therapist') && (
            <SubmitButton
              onClick={() => {
                handleSplitViewShow();
              }}
            >
              {EXPANDED_CHART[language].compare_tracking_data.label}
            </SubmitButton>
          )}
        </Row>
        <div className="row flex-column justify-content-center align-items-center">
          <div className="row" style={{ marginBottom: "10px" }}>
            {patient.caregiverName &&
              EXPANDED_CHART[language].report_detail.therapist[0] +
              patient.caregiverName +
              EXPANDED_CHART[language].report_detail.therapist[1] +
              (patient.patientType ? patient.patientType : "") +
              EXPANDED_CHART[language].report_detail.therapist[2] +
              patient.firstName + " " + patient.lastName +
              EXPANDED_CHART[language].report_detail.therapist[3]
            }
          </div>

          <div className="row" style={{ marginBottom: "10px" }}>
            {!patient.caregiverName &&
              EXPANDED_CHART[language].report_detail.patient[0] +
              (patient.patientType ? patient.patientType : "") +
              EXPANDED_CHART[language].report_detail.patient[1] +
              patient.firstName + " " + patient.lastName +
              EXPANDED_CHART[language].report_detail.patient[2]
            }
          </div>

          {errorMessage && (
            <div style={{ marginBottom: "10px" }}>
              <div style={{ textAlign: "center" }}>{errorMessage}</div>
              {TPList &&
                TPList.map((tp, index) => (
                  <div>
                    <PillButton
                      autoMargin
                      key={index}
                      onClick={() => {
                        handleSubmit(tp.user_id);
                      }}
                    >
                      {tp.first_name + "," + tp.last_name}
                    </PillButton>
                  </div>
                ))}
            </div>
          )}

          {!patient.caregiverName && splitView && !errorMessage && (
            <div className="row" style={{ marginBottom: "10px" }}>
              {
                EXPANDED_CHART[language].compare_tracking_data.text[0] +
                otherUserInfo.tp_first_name + " " + otherUserInfo.tp_last_name +
                EXPANDED_CHART[language].compare_tracking_data.text[1]
              }
            </div >
          )}

          {
            patient.caregiverName && splitView && !errorMessage && (
              <div className="row" style={{ marginBottom: "10px" }}>
                {
                  EXPANDED_CHART[language].compare_tracking_data.text[0] +
                  otherUserInfo.patient_first_name + " " + otherUserInfo.patient_last_name +
                  EXPANDED_CHART[language].compare_tracking_data.text[1]
                }
              </div>
            )
          }
        </div >
      </ComponentWrapper >
      <ModalPdf
        isReport={true}
        show={showLineModal}
        handleClose={handleLineModalClose}
        chart={[
          `sdoh-doughnut`,
          `another-sdoh-doughnut`,
          `medical-doughnut`,
          `another-medical-doughnut`,
          `sdoh-barChart`,
          `another-sdoh-barChart`,
          `medical-barChart`,
          "another-medical-barChart",
          `feeling-bar`,
          `another-feeling-bar`,
          `${assignedDays}day-lineChart`,
          `another-${assignedDays}day-lineChart`,
        ]}
        data={data}
        anotherDataForComparasion={TPOrPatientTrackedData}
        additionalNotes={additionalNotes}
        days={assignedDays}
      />
      <Row>
        <Col md={6}>
          <ComponentWrapper>
            <div className={`sdoh-doughnut`}>
              <DoughnutSdoh
                splitView={splitView}
                data={sdohData}
                days={assignedDays}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        {splitView && !errorMessage && (
          <Col md={6}>
            <ComponentWrapper>
              <div className={`another-sdoh-doughnut`}>
                <DoughnutSdoh
                  splitView={splitView}
                  data={anotherSdohData}
                  days={assignedDays}
                  userName={
                    patient.caregiverName
                      ? otherUserInfo.patient_first_name
                      : otherUserInfo.tp_first_name
                  }
                />
              </div>
            </ComponentWrapper>
          </Col>
        )}
        <Col md={6}>
          <ComponentWrapper>
            <div className={`medical-doughnut`}>
              <DoughnutMedical
                splitView={splitView}
                data={medicalData}
                days={assignedDays}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>

        {splitView && !errorMessage && (
          <Col md={6}>
            <ComponentWrapper>
              <div className={`another-medical-doughnut`}>
                <DoughnutMedical
                  splitView={splitView}
                  data={anotherMedicalData}
                  days={assignedDays}
                  userName={
                    patient.caregiverName
                      ? otherUserInfo.patient_first_name
                      : otherUserInfo.tp_first_name
                  }
                />
              </div>
            </ComponentWrapper>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <ComponentWrapper>
            <div className={`sdoh-barChart`}>
              <SdohBarChart
                splitView={splitView}
                data={sdohByDays}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        {splitView && !errorMessage && (
          <Col md={6}>
            <ComponentWrapper>
              <div className={`another-sdoh-barChart`}>
                <SdohBarChart
                  splitView={splitView}
                  data={anotherSdohByDays}
                  userName={
                    patient.caregiverName
                      ? otherUserInfo.patient_first_name
                      : otherUserInfo.tp_first_name
                  }
                />
              </div>
            </ComponentWrapper>
          </Col>
        )}
        <Col md={6}>
          <ComponentWrapper>
            <div className={`medical-barChart`}>
              <MedicalBarChart
                splitView={splitView}
                data={medicalData}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        {splitView && !errorMessage && (
          <Col md={6}>
            <ComponentWrapper>
              <div className={`another-medical-barChart`}>
                <MedicalBarChart
                  splitView={splitView}
                  data={anotherMedicalData}
                  userName={
                    patient.caregiverName
                      ? otherUserInfo.patient_first_name
                      : otherUserInfo.tp_first_name
                  }
                />
              </div>
            </ComponentWrapper>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={4}>
          <ComponentWrapper>
            <div className={`feeling-bar`}>
              <FeelingBar
                splitView={splitView}
                data={medicalData}
                userName={
                  patient.caregiverName
                    ? patient.caregiverName
                    : patient.firstName
                }
              />
            </div>
          </ComponentWrapper>
        </Col>
        <Col md={8}>
          <ComponentWrapper>
            <div className={`${assignedDays}day-lineChart`}>
              <Line
                data={newData2}
                width={100}
                height={300}
                options={{
                  ...LineChartOptions,
                  ...extendedChartOption1,
                  ...colorOverRide,
                }}
              />
            </div>
          </ComponentWrapper>
        </Col>

        {splitView && !errorMessage && (
          <Col md={4}>
            <ComponentWrapper>
              <div className={`another-feeling-bar`}>
                <FeelingBar
                  splitView={splitView}
                  data={anotherMedicalData}
                  userName={
                    patient.caregiverName
                      ? otherUserInfo.patient_first_name
                      : otherUserInfo.tp_first_name
                  }
                />
              </div>
            </ComponentWrapper>
          </Col>
        )}

        {splitView && !errorMessage && (
          <Col md={8}>
            <ComponentWrapper>
              <div className={`another-${assignedDays}day-lineChart`}>
                <Line
                  data={anotherNewData2}
                  width={100}
                  height={300}
                  options={{
                    ...LineChartOptions,
                    ...extendedChartOption2,
                    ...colorOverRide,
                  }}
                />
              </div>
            </ComponentWrapper>
          </Col>
        )}
      </Row>
      <SubmitButton onClick={() => history.push("/")}>{GO_BACK[language]}</SubmitButton>
    </>
  );
};

export default ExpandedChart;
